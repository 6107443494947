import React from 'react'
import Page from '@/components/Page'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useContentInProp } from '@/hooks/useContentInProp'
import { Left, MobileFooter } from '@/layouts/styled'
import MobileBack from '@/components/MobileBack'
import { SideNavigationLink } from '@/components/SideNavigationLink'
import { VideoPlayer } from '@/components/VideoPlayer'

const MarksJourneyPage: React.FC = () => {
  const inProp = useContentInProp()
  return (
    <>
      <Left style={{ zIndex: 2 }}>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="left"
            delay={0}
            time={1}
            url="/our-dna/sponsor"
            text="back"
            data-url
          />
        )}
      </Left>
      <Page
        content={<VideoPlayer source="/videos/ohpen_video_h264_twopass.mp4" />}
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          alwaysShow
          variant="light"
          delay={0}
          time={1}
          url="/our-dna/sponsor"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default MarksJourneyPage
